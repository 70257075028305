import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "../hooks/useTranslation";

Faq.propTypes = {
  data: PropTypes.array,
};

function Faq(props) {
  const { t } = useTranslation();
  const { data } = props;

  return (
    <section className="faq">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h6 className="sub-heading">
                <span>{t("faqSubheading")}</span>
              </h6>
              <h4 className="heading">{t("faqHeading")}</h4>
            </div>
          </div>
          <div
            className="roadmap__main"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <div className={`roadmap-box`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ1")}</h5>
                <p className="text">{t("faqA1")}</p>
              </div>
            </div>
            <div className={`roadmap-box right`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ2")}</h5>
                <p className="text">{t("faqA2")}</p>
              </div>
            </div>
            <div className={`roadmap-box`}>
              <div className="content">
                <h5 className="title !text-2xl !font-bold">{t("faqQ3")}</h5>
                <p className="text">{t("faqA3")}</p>
              </div>
            </div>
            <div className={`roadmap-box right`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ4")}</h5>
                <p className="text">{t("faqA4")}</p>
              </div>
            </div>
            <div className={`roadmap-box`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ5")}</h5>
                <p className="text">{t("faqA5")}</p>
              </div>
            </div>
            <div className={`roadmap-box right`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ6")}</h5>
                <p className="text">{t("faqA6")}</p>
              </div>
            </div>
            <div className={`roadmap-box`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ7")}</h5>
                <p className="text">{t("faqA7")}</p>
              </div>
            </div>
            <div className={`roadmap-box right`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ8")}</h5>
                <p className="text">{t("faqA8")}</p>
              </div>
            </div>
            <div className={`roadmap-box`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ9")}</h5>
                <p className="text">{t("faqA9")}</p>
              </div>
            </div>
            <div className={`roadmap-box right`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ10")}</h5>
                <p className="text">{t("faqA10")}</p>
              </div>
            </div>
            <div className={`roadmap-box`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ11")}</h5>
                <p className="text">{t("faqA11")}</p>
              </div>
            </div>
            <div className={`roadmap-box right`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ12")}</h5>
                <p className="text">{t("faqA12")}</p>
              </div>
            </div>
            <div className={`roadmap-box`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ13")}</h5>
                <p className="text">{t("faqA13")}</p>
              </div>
            </div>
            <div className={`roadmap-box right`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ14")}</h5>
                <p className="text">{t("faqA14")}</p>
              </div>
            </div>
            <div className={`roadmap-box`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ15")}</h5>
                <p className="text">{t("faqA15")}</p>
              </div>
            </div>
            <div className={`roadmap-box right`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ16")}</h5>
                <p className="text">{t("faqA16")}</p>
              </div>
            </div>
            <div className={`roadmap-box`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ17")}</h5>
                <p className="text">{t("faqA17")}</p>
              </div>
            </div>
            <div className={`roadmap-box right`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ18")}</h5>
                <p className="text">{t("faqA18")}</p>
              </div>
            </div>
            <div className={`roadmap-box`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ19")}</h5>
                <p className="text">{t("faqA19")}</p>
              </div>
            </div>
            <div className={`roadmap-box right`}>
              <div className="content">
                <h5 className="title text-2xl font-bold">{t("faqQ20")}</h5>
                <p className="text">{t("faqA20")}</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;
