const dataRoadMap = [
  {
    id: 1,
    timeKey: "roadmapTime1",
    titleKey: "roadmapTitle1",
    descKey: "roadmapDesc1",
    class: "",
  },
  {
    id: 2,
    timeKey: "roadmapTime2",
    titleKey: "roadmapTitle2",
    descKey: "roadmapDesc2",
    class: "right",
  },
  {
    id: 3,
    timeKey: "roadmapTime3",
    titleKey: "roadmapTitle3",
    descKey: "roadmapDesc3",
    class: "",
  },
  {
    id: 4,
    timeKey: "roadmapTime4",
    titleKey: "roadmapTitle4",
    descKey: "roadmapDesc4",
    class: "right",
  },
];

export default dataRoadMap;
