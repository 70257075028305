import img5 from "../images/layouts/team-05.png";
import img6 from "../images/layouts/team-06.png";
import img8 from "../images/layouts/team-08.png";

const dataTeam = [
  {
    id: 1,
    img: img5,
    nameKey: "teamMember1Name",
    positionKey: "teamMember1Position",
  },
  {
    id: 2,
    img: img6,
    nameKey: "teamMember2Name",
    positionKey: "teamMember2Position",
  },
  {
    id: 3,
    img: img8,
    nameKey: "teamMember3Name",
    positionKey: "teamMember3Position",
  },
];

export default dataTeam;
