const dataItem = [
  {
    id: 1,
    time: "September 2024",
    title: "What is Ekival?",
    desc: "Ekival is a blockchain-based, non-custodial platform designed for fast, secure, and affordable peer-to-peer money transfers. It enables users to send and receive funds globally using fiat currencies or cryptocurrencies, while ensuring they remain in full control of their funds at all times.",
    class: "",
  },
  {
    id: 2,
    time: "December 2024",
    title: "How does Ekival work?",
    desc: "Ekival leverages blockchain technology to connect senders and recipients directly. Funds are secured in smart contract escrows, ensuring trust and transparency. As a non-custodial platform, Ekival does not hold your funds or private keys—giving you complete control over your money.",
    class: "right",
  },

  {
    id: 3,
    time: "January 2025",
    title: 'What does "non-custodial" mean?',
    desc: "Being non-custodial means Ekival does not take ownership or control of your funds or private keys. Transactions are managed securely through smart contracts on the blockchain, allowing you to maintain full custody of your assets throughout the process.",
    class: "",
  },

  {
    id: 4,
    time: "May 2025",
    title: "What are the benefits of using Ekival?",
    desc: `Non-Custodial Security: Your funds and private keys are always under your control.
Low Fees: Minimal transaction costs compared to traditional remittance services.
Speed: Near-instant processing of transfers.
Transparency: Transactions are secured and recorded on a blockchain.
Global Reach: Support for cross-border transfers in fiat and cryptocurrencies.
Incentives: Users can earn EKI tokens for participation.`,
    class: "right",
  },
  {
    id: 5,
    time: "May 2025",
    title: "What is the EKI token?",
    desc: `The EKI token is the native utility token of the Ekival platform. It can be used for:
Paying transaction fees (with discounts).
Participating in staking and liquidity programs.
Voting on governance decisions within the Ekival ecosystem.
Receiving rewards and incentives for platform usage.
`,
    class: "right",
  },
];

export default dataItem;
