import { useEffect } from 'react';
import i18next from 'i18next';

const LANGUAGE_KEY = 'ekival_language';

export const useLanguagePersistence = () => {
  useEffect(() => {
    // Récupérer la langue sauvegardée ou utiliser la langue par défaut (fr)
    const savedLanguage = localStorage.getItem(LANGUAGE_KEY) || 'fr';
    
    // Appliquer la langue sauvegardée
    i18next.changeLanguage(savedLanguage);

    // Écouter les changements de langue
    const handleLanguageChange = (lng) => {
      localStorage.setItem(LANGUAGE_KEY, lng);
    };

    i18next.on('languageChanged', handleLanguageChange);

    return () => {
      i18next.off('languageChanged', handleLanguageChange);
    };
  }, []);

  // Fonction pour changer la langue
  const changeLanguage = (lang) => {
    i18next.changeLanguage(lang);
    localStorage.setItem(LANGUAGE_KEY, lang);
  };

  return { changeLanguage };
};
