import React from "react";
import { useLanguage } from "../context/LanguageContext";
import { useTranslation } from "../hooks/useTranslation";

const LanguageToggle = () => {
  const { toggleLanguage } = useLanguage();
  const { t } = useTranslation();

  return (
    <button
      onClick={toggleLanguage}
      className="px-3 py-1 rounded-md bg-primary text-white hover:bg-primary-dark bg-indigo-500"
    >
      {t("switchLanguage")}
    </button>
  );
};

export default LanguageToggle;
