export const en = {
  // Navigation
  Home: "Home",
  RoadMap: "RoadMap",
  Team: "Team",
  Contact: "Contact",

  // Header
  welcomeMessage: "Welcome to Ekival",
  learnMore: "Learn More",
  launchApp: "Launch App",

  // About
  aboutTitle: "About Us",
  aboutDescription: "We are an innovative company specialized in...",
  ourMission: "Our Mission",
  ourVision: "Our Vision",

  // Services
  servicesTitle: "Our Services",
  serviceDescription: "Discover our exceptional services",

  // Portfolio
  portfolioTitle: "Our Portfolio",
  viewProject: "View Project",

  // Contact
  contactTitle: "Contact Us",
  name: "Name",
  email: "Email",
  message: "Message",
  send: "Send",

  // Contact Page
  contactSubheading: "Get in Touch",
  contactHeading1: "Let's Start Working",
  contactHeading2: "Together",
  contactInfoTitle: "Contact information",
  contactAddress: "Ekival Remit Ltd. 2020 Lanthier Dr #9, Suite #236 Ottawa, ON K4A 3V4",
  contactPhone: "+1-866-966-6282",
  contactEmail: "support@ekival.com",
  contactImageAlt: "Contact image",
  
  // Contact Form
  contactFormName: "Your name",
  contactFormEmail: "Your email",
  contactFormPhone: "Your phone",
  contactFormSubject: "Subject",
  contactFormMessage: "Message",
  contactFormSubmit: "Send Now",
  contactFormSelectDefault: "Select",
  contactFormSelectSupport: "Technical Support",
  contactFormSelectBusiness: "Business Opportunities",
  contactFormSelectOther: "Other",
  
  // Map
  contactMapTitle: "Our location on the map",

  // Footer
  allRightsReserved: "All rights reserved",
  privacyPolicy: "Privacy Policy",
  termsOfService: "Terms of Service",

  // Common
  readMore: "Read More",
  sendMessage: "Send Message",
  subscribe: "Subscribe",
  newsletter: "Newsletter",
  followUs: "Follow Us",
  currentTime: "The current local time is:",
  loading: "Loading...",
  error: "Error",
  success: "Success",

  // Banner
  bannerTitle1: "Your solution",
  bannerTitle2: "for global",
  bannerTitle3: "peer-2-peer",
  bannerTitle4: "money and crypto transfer",
  bannerDescription: "Provide liquidity and receive either Ada or a stablecoin of your choice, securely through Ekival.",
  currentBid: "Current Bid",
  mobileMoneyTitle: "Mobile Money",
  mobileMoneySubtitle: "transfer",
  mobileMoneyAlt: "mobile money",

  // FAQ Section
  faqSubheading: "FAQs",
  faqHeading: "Frequently Asked Questions",
  faqDescription: "Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.",
  faqImageAlt: "FAQ illustration",

  // FAQ Tabs
  faqTabCrypto: "Cryptocurrency",
  faqTabNFT: "NFT Token",
  faqTabCollection: "Collection",
  faqTabTrading: "Crypto Trading",

  // FAQ Items
  faq1Title: "What is cryptocurrency?",
  faq1Text: "Cryptocurrency is a digital or virtual form of currency that uses cryptography for security...",

  faq2Title: "How do I start trading?",
  faq2Text: "To start trading, you'll need to create an account on a cryptocurrency exchange...",

  // FAQ Page
  faqPageTitle: "FAQ",
  faqSubheading: "FAQs",
  faqHeading: "Frequently Asked Questions (FAQs) for Ekival",

  // FAQ Questions and Answers
  faqQ1: "What is Ekival?",
  faqA1: "Ekival is a blockchain-based, non-custodial platform designed for fast, secure, and affordable peer-to-peer money transfers. It enables users to send and receive funds globally using fiat currencies or cryptocurrencies, while ensuring they remain in full control of their funds at all times.",

  faqQ2: "How does Ekival work?",
  faqA2: "Ekival leverages blockchain technology to connect senders and recipients directly. Funds are secured in smart contract escrows, ensuring trust and transparency. As a non-custodial platform, Ekival does not hold your funds or private keys—giving you complete control over your money.",

  faqQ3: "What does \"non-custodial\" mean?",
  faqA3: "Being non-custodial means Ekival does not take ownership or control of your funds or private keys. Transactions are managed securely through smart contracts on the blockchain, allowing you to maintain full custody of your assets throughout the process.",

  faqQ4: "What are the benefits of using Ekival?",
  faqA4: "Non-Custodial Security: Your funds and private keys are always under your control. Low Fees: Minimal transaction costs compared to traditional remittance services. Speed: Near-instant processing of transfers. Transparency: Transactions are secured and recorded on a blockchain. Global Reach: Support for cross-border transfers in fiat and cryptocurrencies. Incentives: Users can earn EKI tokens for participation.",

  faqQ5: "What is the EKI token?",
  faqA5: "The EKI token is the native utility token of the Ekival platform. It can be used for: Paying transaction fees (with discounts). Participating in staking and liquidity programs. Voting on governance decisions within the Ekival ecosystem. Receiving rewards and incentives for platform usage.",

  faqQ6: "How do I earn EKI tokens?",
  faqA6: "You can earn EKI tokens through: Participating in the airdrop campaigns by staking ADA to the Goma Stake Pool. Use the Ekival platform and mint your rewards. Engaging in the rewards program for active users and delegators. Referrals and promotions.",

  faqQ7: "What is the Goma Stake Pool, and how does it relate to Ekival?",
  faqA7: "The Goma Stake Pool is a Cardano-based staking pool and a key partner of Ekival. Delegators who stake ADA to the Goma Stake Pool by the snapshot date (e.g., December 31) are eligible for EKI token rewards. Rewards are based on the amount and duration of ADA delegation.",

  faqQ8: "How can I send money using Ekival?",
  faqA8: "Connect with your wallet: On the Ekival platform, signing a JWT token transaction will allow you to open a secure session which grants secure transactions for communication and profile updates. Fund Your Wallet: Add funds with Ada or your mobile money account where applicable. Initiate an Offer: Access your Dashboard, enter the amount, and other details about your offer of liquidity (Buy or Sell) Accept an Order: Agree with Ekival users offering to take your offer. Track Status: Monitor the transfer via the Ekival dashboard until the recipient confirms receipt. Complete Transaction: The smart contract will allocate the funds minus fees.",

  faqQ9: "What currencies are supported on Ekival?",
  faqA9: "Ekival supports both fiat currencies (e.g., CDF, local currencies via mobile money) and cryptocurrencies (e.g., ADA, EKI, Djed). Additional currencies may be added based on demand.",

  faqQ10: "What fees does Ekival charge?",
  faqA10: "Ekival charges a small transaction fee ranging from 0.5% to 1.5%, depending on the transfer method. Discounts are available for users paying fees with EKI tokens.",

  faqQ11: "How secure is Ekival?",
  faqA11: "Ekival prioritizes security through: Non-Custodial Design: Users retain full control of their funds. Blockchain Technology: Transactions are immutable and transparent. Smart Contracts: Funds are held in escrow until both parties confirm the transaction. Encryption: User data and funds are secured with advanced encryption protocols. Regulatory Compliance: Ekival complies with AML (Anti-Money Laundering) and KYC (Know Your Customer) regulations.",

  faqQ12: "How do I stake ADA to qualify for EKI token rewards?",
  faqA12: "Open your Cardano-compatible wallet (e.g., Yoroi, Daedalus, or Nami). Search for the Goma Stake Pool. Delegate your ADA to the pool before the snapshot date. Maintain your delegation for maximum rewards.",

  faqQ13: "Can I use Ekival without cryptocurrency?",
  faqA13: "Ekival supports fiat transfers through mobile money, cash hand-outs and bank accounts. You would however need the minimum amount of Ada to confirm the transaction on the blockchain as Ekival is non-custodial. Cryptocurrency-only options are available for users who prefer blockchain-based transactions.",

  faqQ14: "What happens if a transaction fails?",
  faqA14: "If a transaction fails: Funds held in escrow are still safe and you will not be charged as the wallet would not submit the transaction. As a non-custodial platform, Ekival ensures your funds remain secure throughout the process. Ekival's support team is available 24/7 to assist with any issues such as extending deadlines.",

  faqQ15: "How do I contact customer support?",
  faqA15: "You can reach Ekival's customer support via: Email: support@ekival.com Live Chat: Available on the Ekival website and mobile app. Phone: 1-866-966-6282.",

  faqQ16: "How is Ekival different from traditional remittance services?",
  faqA16: "Ekival offers several advantages over traditional services: Non-Custodial Security: You retain control of your funds throughout the transaction process. Lower Fees: Minimal costs compared to traditional remittance services. Blockchain-Based Transparency: Ensures trust and accountability. Incentives: Earn EKI tokens for participation and staking.",

  faqQ17: "Is Ekival available worldwide?",
  faqA17: "Yes, Ekival is designed to support global transactions. Availability may vary depending on local regulations and partner integrations. US residents are required to consult our terms of usage.",

  faqQ18: "How does Ekival ensure compliance with regulations?",
  faqA18: "Ekival Remit Ltd. is registered as a Money Services Business (MSB) in Canada and complies with AML and KYC requirements. Its non-custodial design also reduces regulatory burdens while ensuring user security.",

  faqQ19: "Can businesses use Ekival?",
  faqA19: "Absolutely! Ekival offers solutions for businesses, including: Cross-border payroll and supplier payments. Reduced fees for bulk transactions. Custom integrations for high-volume transfers. Fiat-to-Crypto transfer for import-export needs.",

  faqQ20: "What is the minimum transfer amount?",
  faqA20: "The minimum transfer amount varies by currency and method. For cryptocurrency transfers, it's typically equivalent to $10 USD. For fiat transfers, minimums may vary by region and transfer method.",

  // Roadmap
  roadmapSubheading: "Road Map",
  roadmapHeading: "The Journey of EKIVAL",
  roadmapLineAlt: "timeline",
  viewFullRoadmap: "View Full Road Map",

  // Roadmap Timeline
  roadmapTime1: "September 2024",
  roadmapTitle1: "Testnet & Beta",
  roadmapDesc1: "Finalise and integrate the smart contracts on Testnet while on-boarding early testers who had provided feedback via the July user Survey",

  roadmapTime2: "December 2024",
  roadmapTitle2: "Community Engagement and Token Sale Preparation",
  roadmapDesc2: "Expand social media campaigns on Discord, Facebook, and Twitter to coordinate community events, AMAs, and webinars to explain Ekival's benefits. The whitepaper on token allocation will seek feedback from community members and testers",

  roadmapTime3: "January 2025",
  roadmapTitle3: "Pre-Mainnet Launch and Final Testing",
  roadmapDesc3: "Finalise the mainnet infrastructure and deployment plan, focusing on security and performance optimizations. Community feedback and legal compliance reviews in preparation for mainnet launch",

  roadmapTime4: "May 2025",
  roadmapTitle4: "Mainnet Launch and Post-Launch Support",
  roadmapDesc4: "Most users in Africa rely on mobile devices, Liquidity being offered through Ekival can be reserved through an API",

  // Team
  teamSubheading: "Team Members",
  teamHeading: "Our Amazing Team Members",
  teamMemberImageAlt: "Photo of {{name}}",
  
  // Team Members
  teamMember1Name: "Frédéric Samvura",
  teamMember1Position: "Business Developer",
  teamMember2Name: "M. Ali Modiri",
  teamMember2Position: "Smart Contract Developer",
  teamMember3Name: "Ali Khaleghi Far",
  teamMember3Position: "Front-End Developer",

  // Social Media
  socialFacebook: "Follow us on Facebook",
  socialTwitter: "Follow us on Twitter",
  socialTelegram: "Join us on Telegram",

  // Language
  switchLanguage: "FR",
};
