import React, { createContext, useContext } from 'react';
import { useLanguagePersistence } from '../hooks/useLanguagePersistence';
import { useTranslation } from 'react-i18next';

const LanguageContext = createContext();

export function LanguageProvider({ children }) {
  const { i18n } = useTranslation();
  const { changeLanguage } = useLanguagePersistence();

  const value = {
    currentLanguage: i18n.language,
    changeLanguage: (lang) => {
      changeLanguage(lang);
    },
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
}

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
}
