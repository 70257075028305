import React from 'react';
import { useLanguage } from '../context/LanguageContext';
import './LanguageSelector.scss';

export function LanguageSelector() {
  const { currentLanguage, changeLanguage } = useLanguage();

  return (
    <div className="language-selector" style={{ marginLeft: '20px' }}>
      <button
        onClick={() => changeLanguage('fr')}
        className={`lang-btn ${currentLanguage === 'fr' ? 'active' : ''}`}
      >
        FR
      </button>
      <button
        onClick={() => changeLanguage('en')}
        className={`lang-btn ${currentLanguage === 'en' ? 'active' : ''}`}
      >
        EN
      </button>
    </div>
  );
}
