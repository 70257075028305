import { React, useEffect } from "react";
import AOS from "aos";
import { Route, Routes } from "react-router-dom";
import { LanguageProvider } from "./context/LanguageContext";

import routes from "./pages";
import Page404 from "./pages/404";
import Header from "./components/header/Header";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <>
      <LanguageProvider>
        <Header />

        <Routes>
          {routes.map((data, idx) => (
            <Route key={idx} path={data.path} element={data.component} exact />
          ))}

          <Route path="*" element={<Page404 />} />
        </Routes>
      </LanguageProvider>
    </>
  );
}

export default App;
