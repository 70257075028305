import React, { useState } from "react";
import PropTypes from "prop-types";
import img from "../../assets/images/background/line.png";
import { useTranslation } from "../../hooks/useTranslation";

Roadmap.propTypes = {
  data: PropTypes.array,
};

function Roadmap(props) {
  const { data } = props;
  const { t } = useTranslation();

  const [dataBlock] = useState({
    subheading: t("roadmapSubheading"),
    heading: t("roadmapHeading"),
  });

  return (
    <section className="roadmap">
      <img src={img} alt={t("roadmapLineAlt")} className="img-line" />
      <div className="shape"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading pd">{dataBlock.heading}</h3>
            </div>

            <div
              className="roadmap__main"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              {data.map((idx) => (
                <div key={idx.id} className={`roadmap-box ${idx.class}`}>
                  <div className="time">{t(idx.timeKey)}</div>
                  <div className="content">
                    <h5 className="title">{t(idx.titleKey)}</h5>
                    <p className="text">{t(idx.descKey)}</p>
                  </div>
                </div>
              ))}

              <div className="icon"></div>
            </div>
            <div className="button">
              {/* <Button title={t('viewFullRoadmap')} link='/road-map' /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Roadmap;
